
import { defineComponent } from 'vue'
import { treeResponseType } from '@/types'
import Checkbox from '@/components/UI/Checkbox.vue'
import InputElement from '@/components/UI/Input.vue'
import ConvertToCommas from '@/filters/ConvertToCommas'
export default defineComponent({
  name: 'HierarchyRow',
  components: {
    Checkbox,
    InputElement
  },
  props: {
    data: {
      type: [Object as () => treeResponseType]
    },
    indentSize: {
      type: Number
    },
    showInputBox: {
      type: Number
    }
  },
  emits: ['nodeExpand', 'toggleCheckbox', 'showDiscountInput', 'discountInputChange', 'saveDiscount'],
  setup(props, { emit }) {
    const onNodeExpand = (item) => {
      emit('nodeExpand', item)
    }
    const onToggleCheckbox = ($event, node) => {
      emit('toggleCheckbox', $event, node)
    }
    const onShowDiscountInput = (id) => {
      emit('showDiscountInput', id)
    }
    const onDiscountInputChange = (value, name) => {
      emit('discountInputChange', value, name)
    }
    const saveDiscountInput = (node) => {
      emit('saveDiscount', node)
    }
    return {
      onNodeExpand,
      onToggleCheckbox,
      onDiscountInputChange,
      onShowDiscountInput,
      saveDiscountInput,
      ConvertToCommas
    }
  }
})
