
import { defineComponent, ref, reactive, computed, onMounted, watch, onUnmounted } from 'vue'
import Input from '@/components/UI/Input.vue'
import InputElement from '@/components/UI/Input.vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import Button from '@/components/UI/Button.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import Popup from '@/components/UI/Popup.vue'
import Loader from '@/components/UI/Loader.vue'
import MessageBar from '@/components/UI/MessageBar.vue'
import HierarchyRow from '@/components/UI/HierarchyRow.vue'
import { DiscountsActionTypes } from '@/store/discounts/actions'
import { ActionTypes } from '@/store/options/actions'
import PopupElement from '@/components/Popup.vue'
import { useStore } from '@/store'
import { CompanyActionTypes } from '@/store/company'
import getCompany from '@/mixins/getCompany'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { MutationTypes } from '@/store/options/mutations'
export default defineComponent({
  name: 'Discount',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const roles = computed(() => store.state.auth.currentUser?.roles)
    const loader = computed(() => store.state.discounts.loader)
    const componentKey = ref(0)
    const { compId, companyDetailState } = getCompany()
    const carBrandsModelsLevels = computed(() => store.state.options.carBrandsModelsLevels)
    const foundBrands = computed(() => {
      let filBrands = carBrandsModelsLevels.value
      if (store.state.options.searchString.length > 0) {
        filBrands = filBrands.filter(
          (brand) =>
            brand.brand
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
              .indexOf(store.state.options.searchString.toLowerCase()) !== -1
        )
      }
      return filBrands
    })
    const filteredBrands = computed(() =>
      foundBrands.value.map((item) => {
        return { label: item.brand, value: item.brand_111 }
      })
    )
    const filteredModels = computed(() => store.getters.filteredModels)
    const filteredLevels = computed(() => store.getters.filteredLevels)
    const getCarBrandsDiscount = computed(() => store.state.discounts.getCarBrandsDiscount)
    const setDiscountResponse = computed(() => store.state.discounts.setDiscountResponse)
    const sortOrder = ref('asc')
    const filterData = ref([])
    let updateData = reactive({})
    const tmpDiscount = ref()
    const selectedNodes = ref<Array<number>>([])
    const openedNodes = ref([])
    const showPopUp = ref(false)
    const showConfirmationPopup = ref(false)
    const resetDiscount = ref(false)
    const showInputBox = ref(0)
    const carHeadings = reactive([
      { id: 'car_heading1', value: 'car_heading1', key: 'cars' },
      { id: 'dis_heading2', value: 'dis_heading2', key: 'discount' }
    ])
    const filtersChecks = reactive([
      { id: 'driver_benefit', value: false },
      { id: 'without_discount', value: false }
    ])
    const searchBy = route.query.query ? ref(String(route.query.query)) : ref('')
    const models = ref([])
    const versions = ref([])
    const selectedBrand = ref({ name: '', val: '' })
    const selectedModel = ref('')
    const selectedLevel = ref('')

    const selectBrand = (data, onLoad) => {
      selectedModel.value = ''
      selectedLevel.value = ''
      selectedNodes.value = []
      models.value = []
      versions.value = []
      const label = onLoad ? data : data.value
      if (data) {
        const brandData = carBrandsModelsLevels.value.filter((item) => {
          if (item['brand_111'] == label) return item
        })
        brandData[0].models.map((e) => {
          models.value.push(e.model)
        })
        selectedBrand.value = { name: brandData[0].brand, val: brandData[0].brand_111 }
      } else {
        selectedBrand.value = { name: '', val: '' }
      }

      const query: { brand?: string } = Object.assign({}, route.query, { brand: label })
      if (!label) delete query.brand
      router.push({ query })
      store.commit(MutationTypes.SET_SELECTED_MODELS, models.value.sort())
      store.commit(MutationTypes.SET_SEARCH_STRING, selectedBrand.value.name)
      store.commit(MutationTypes.SET_SEARCH_MODELS, '')
      store.commit(MutationTypes.SET_SELECTED_LEVELS, versions.value)
      store.commit(MutationTypes.SET_SEARCH_LEVELS, '')
      componentKey.value += 1
    }
    const selectModel = (i, onLoad) => {
      selectedLevel.value = ''
      let searchVal = ''
      let label = ''
      versions.value = []
      selectedNodes.value = []
      if (i) {
        const brandData = carBrandsModelsLevels.value.filter((item) => {
          if (item['brand'] == selectedBrand.value.name) return item
        })
        brandData[0].models.map((e) => {
          const condlabel = onLoad ? e.model_112 : e.model
          if (condlabel == i) {
            searchVal = e.model_112
            label = e.model
            e.levels.map((ver) => {
              versions.value.push(ver.level)
            })
          }
        })
      }
      selectedModel.value = label
      const query: { model?: string } = Object.assign({}, route.query, { model: encodeURIComponent(label) })
      if (!searchVal) delete query.model
      router.push({ query })
      store.commit(MutationTypes.SET_SEARCH_MODELS, label)
      store.commit(MutationTypes.SET_SELECTED_LEVELS, versions.value.sort())
      store.commit(MutationTypes.SET_SEARCH_LEVELS, '')
      componentKey.value += 1
    }
    const selectLevel = (ver, onLoad) => {
      selectedNodes.value = []
      let value = ''
      let label = ''
      if (ver) {
        const brandData = carBrandsModelsLevels.value.filter((item) => {
          if (item['brand'] == selectedBrand.value.name) return item
        })
        brandData[0].models.map((m) => {
          if (m.model == selectedModel.value) {
            m.levels.map((v) => {
              const condlabel = onLoad ? v.level_402 : v.level
              if (condlabel == ver) {
                label = v.level
                value = v.level_402
              }
            })
          }
        })
      }
      selectedLevel.value = label
      store.commit(MutationTypes.SET_SEARCH_LEVELS, label)
      const query: { level?: string } = Object.assign({}, route.query, { level: value })
      if (!ver) delete query.level
      router.push({ query })
      componentKey.value += 1
    }
    const onEnterSearch = () => {
      selectedNodes.value = []
      const searchedTerm = (searchBy.value as string).trim().toLowerCase()
      const query: { query?: string } = Object.assign({}, route.query, { query: searchedTerm })
      if (!searchedTerm) {
        delete query.query
      }
      router.push({ query })
    }
    const onInputSearch = (value) => {
      searchBy.value = value
      if (!value) onEnterSearch()
    }
    const onCheckDriverBenefit = async (event) => {
      const formData = new FormData()
      formData.append('name', JSON.stringify(companyDetailState.value.name))
      formData.append('email', JSON.stringify(companyDetailState.value.email))
      formData.append('phoneNumber', JSON.stringify(companyDetailState.value.phoneNumber))
      formData.append('address', JSON.stringify(companyDetailState.value.address))
      formData.append('postalCode', JSON.stringify(companyDetailState.value.postalCode))
      formData.append('city', JSON.stringify(companyDetailState.value.city))
      formData.append('logo', companyDetailState.value.logo)
      formData.append('_method', 'PUT')
      if (event.target.checked) {
        formData.append('benefitDriver', 'true')
      } else {
        formData.append('benefitDriver', 'false')
      }
      await store.dispatch(CompanyActionTypes.UPDATE_COMPANYBYID, { companyId: compId, body: formData })
    }
    const filterWithoutDiscount = (event) => {
      if (event.target.checked) {
        const query: { discount?: string } = Object.assign({}, route.query, { discount: 'undefined' })
        router.push({ query })
      } else {
        const query: { discount?: string } = Object.assign({}, route.query, { discount: '' })
        delete query.discount
        router.push({ query })
      }
    }
    const filterDiscountData = async () => {
      if (getCarBrandsDiscount.value.status == 'success') {
        filterData.value = []
        for (const brandArr of Object.values(getCarBrandsDiscount.value.carDiscountResponse)) {
          const brandData = reactive({
            id: brandArr.id,
            name: brandArr.name,
            discount:
              updateData[brandArr.id] == '' || updateData[brandArr.id] >= 0
                ? updateData[brandArr.id]
                : brandArr.discount,
            expandable: false,
            isOpen: false,
            isChecked: selectedNodes.value.includes(brandArr.id) ? true : false,
            parentDiscount: null,
            nodes: []
          })

          // loop if models
          if (brandArr.models) {
            brandData.expandable = true
            brandData.isOpen =
              selectedBrand.value.val.toLowerCase() == brandData.name.toLowerCase()
                ? true
                : openedNodes.value.includes(brandArr.id)
                ? true
                : false
            for (const modelsArr of Object.values(brandArr.models)) {
              const modelData = reactive({
                id: modelsArr.id,
                name: modelsArr.name,
                discount:
                  updateData[modelsArr.id] == '' || updateData[modelsArr.id] >= 0
                    ? updateData[modelsArr.id]
                    : modelsArr.discount,
                expandable: false,
                isOpen: false,
                isChecked: selectedNodes.value.includes(modelsArr.id) ? true : false,
                parentDiscount: brandData.discount,
                nodes: []
              })

              // loop if levels
              if (modelsArr.levels) {
                modelData.expandable = true
                modelData.isOpen = openedNodes.value.includes(modelsArr.id)
                  ? true
                  : brandData.isOpen && selectedBrand.value.name
                  ? true
                  : false
                for (const levelsArr of Object.values(modelsArr.levels)) {
                  const levelData = reactive({
                    id: levelsArr.id,
                    name: levelsArr.name,
                    discount:
                      updateData[levelsArr.id] == '' || updateData[levelsArr.id] >= 0
                        ? updateData[levelsArr.id]
                        : levelsArr.discount,
                    expandable: false,
                    isOpen: false,
                    isChecked: selectedNodes.value.includes(levelsArr.id) ? true : false,
                    parentDiscount: modelData.discount ? modelData.discount : brandData.discount,
                    nodes: []
                  })

                  // loop if versions
                  if (levelsArr.versions) {
                    levelData.expandable = true
                    levelData.isOpen =
                      brandData.isOpen && selectedBrand.value.name
                        ? true
                        : openedNodes.value.includes(levelsArr.id)
                        ? true
                        : false
                    for (const versionArr of Object.values(levelsArr.versions)) {
                      const verData = reactive({
                        id: versionArr.id,
                        name: versionArr.name,
                        discount:
                          updateData[versionArr.id] == '' || updateData[versionArr.id] >= 0
                            ? updateData[versionArr.id]
                            : versionArr.discount,
                        isChecked: selectedNodes.value.includes(versionArr.id) ? true : false,
                        parentDiscount: levelData.discount
                          ? levelData.discount
                          : modelData.discount
                          ? modelData.discount
                          : brandData.discount
                      })

                      levelData.nodes.push(verData)
                    }
                  }
                  modelData.nodes.push(levelData)
                }
              }
              brandData.nodes.push(modelData)
            }
          }
          filterData.value.push(brandData)
        }
      } else {
        filterData.value = []
      }
    }
    const sortIcon = (value) => {
      let icon = 'default-icon '
      if (route.query.orderBy && route.query.order) {
        if (route.query.orderBy === value) {
          icon += route.query.order === 'asc' ? 'sort-asc' : 'sort-desc'
        }
      }
      return icon
    }
    const sortBy = (value) => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
      const query = Object.assign({}, route.query, { orderBy: value, order: sortOrder.value })
      router.push({ query })
    }
    const selectAllCheckbox = ($event) => {
      selectedNodes.value = []
      if ($event.target.checked) {
        for (const data of Object.values(getCarBrandsDiscount.value.carDiscountResponse)) {
          selectedNodes.value.push(data.id)
        }
      }
      filterDiscountData()
    }
    const saveDiscount = async (data) => {
      await store.dispatch(DiscountsActionTypes.UPDATE_DISCOUNTS, { companyId: compId, data: data })
      if (setDiscountResponse.value.status == 'success') {
        filterDiscountData()
      }
    }
    const deleteDiscount = async (data) => {
      await store.dispatch(DiscountsActionTypes.DELETE_DISCOUNTS, { companyId: compId, data: data })
      if (setDiscountResponse.value.status == 'success') {
        filterDiscountData()
      }
    }
    const resetAllDiscount = async () => {
      await store.dispatch(DiscountsActionTypes.RESET_DISCOUNTS, { companyId: compId })
      if (setDiscountResponse.value.status == 'success') {
        updateData = {}
        filterDiscountData()
      }
    }
    const convertToNumberAndFloat = (selectedOption) => {
      return selectedOption.replace(/,/g, '.')
    }
    const onShowDiscountInput = (id) => {
      showInputBox.value = parseInt(id)
    }
    const onDiscountInputChange = (value) => {
      tmpDiscount.value = convertToNumberAndFloat(value)
    }
    const saveDiscountInput = (node) => {
      showInputBox.value = 0
      if (tmpDiscount.value) {
        const data = {
          percentage: tmpDiscount.value,
          carDiscountIds: [parseInt(node.id)]
        }
        updateData[node.id] = tmpDiscount.value
        saveDiscount(data)
      } else {
        updateData[node.id] = ''
        deleteDiscount({ carDiscountPercentageIds: [parseInt(node.id)] })
      }
    }
    const onNodeExpand = (node) => {
      if (node.expandable) node.isOpen = !node.isOpen
      if (openedNodes.value.indexOf(node.id) === -1) openedNodes.value.push(node.id)
      else openedNodes.value.splice(openedNodes.value.indexOf(node.id), 1)
    }
    const onToggleCheckbox = ($event, node) => {
      showInputBox.value = 0
      tmpDiscount.value = ''
      node.isChecked = !node.isChecked
      if ($event.target.checked && selectedNodes.value.indexOf(node.id) === -1) {
        selectedNodes.value.push(node.id)
      } else {
        selectedNodes.value.splice(selectedNodes.value.indexOf(node.id), 1)
      }
    }
    const showEditPopup = () => {
      showInputBox.value = 0
      tmpDiscount.value = ''
      showPopUp.value = true
    }
    const onEditDiscountSave = () => {
      if (tmpDiscount.value && selectedNodes.value.length) {
        const data = {
          percentage: tmpDiscount.value,
          carDiscountIds: selectedNodes.value
        }
        selectedNodes.value.map((id) => {
          updateData[id] = tmpDiscount.value
        })
        saveDiscount(data)
        showPopUp.value = false
      }
    }
    const haveConfirmationPopup = () => {
      showPopUp.value = false
      if (selectedNodes.value.length) {
        showConfirmationPopup.value = true
      }
    }
    const haveResetConfirmationPopup = () => {
      resetDiscount.value = true
      showPopUp.value = false
      showConfirmationPopup.value = true
    }
    const onRemoveDiscount = () => {
      showInputBox.value = 0
      if (resetDiscount.value) {
        resetAllDiscount()
        showConfirmationPopup.value = false
      } else if (selectedNodes.value.length) {
        selectedNodes.value.map((id) => {
          updateData[id] = ''
        })
        deleteDiscount({ carDiscountPercentageIds: selectedNodes.value })
        showConfirmationPopup.value = false
      }
    }
    const initializeRequest = async (query) => {
      store.dispatch(DiscountsActionTypes.GET_ALL_DISCOUNTCARS, { companyId: compId, query: query })
      await store.dispatch(ActionTypes.GET_CAR_BRANDS_MODELS)
    }
    const initializeFilters = async (query) => {
      if (query.brand) {
        selectBrand(query.brand, true)
        if (query.model) {
          selectModel(query.model, true)
          if (query.level) selectLevel(query.level, true)
        }
      }
      if (query.discount) {
        filtersChecks[1].value = true
      }
    }
    watch(
      () => store.state.options.searchString,
      () => {
        if (route.query.brand && !store.state.options.searchString) {
          selectBrand('', false)
          delete route.query.brand
        }
      }
    )
    watch(
      () => store.state.options.searchModels,
      () => {
        if (route.query.model && !store.state.options.searchModels) {
          selectModel('', false)
          delete route.query.model
        }
      }
    )
    watch(
      () => store.state.options.searchLevels,
      () => {
        if (route.query.level && !store.state.options.searchLevels) selectLevel('', false)
      }
    )

    watch(
      () => route.query,
      () => {
        if (route.name == 'Discount') {
          initializeRequest(route.query)
          if (route.query.discount == 'undefined') filtersChecks[1].value = true
          else filtersChecks[1].value = false
        }
      }
    )
    onMounted(async () => {
      await initializeRequest(route.query)
      await initializeFilters(route.query)
    })
    onUnmounted(() => {
      store.commit(MutationTypes.SET_SEARCH_STRING, '')
      store.commit(MutationTypes.SET_SEARCH_MODELS, '')
      store.commit(MutationTypes.SET_SEARCH_LEVELS, '')
    })
    watch(
      () => getCarBrandsDiscount.value,
      () => {
        filterDiscountData()
      }
    )

    return {
      searchBy,
      filterData,
      filtersChecks,
      componentKey,
      filteredBrands,
      filteredModels,
      filteredLevels,
      models,
      versions,
      selectedBrand,
      selectedModel,
      selectedLevel,
      selectModel,
      selectBrand,
      selectLevel,
      loader,
      carHeadings,
      getCarBrandsDiscount,
      companyDetailState,
      showInputBox,
      roles,
      selectedNodes,
      tmpDiscount,
      showPopUp,
      showConfirmationPopup,
      haveResetConfirmationPopup,
      onInputSearch,
      onEnterSearch,
      onCheckDriverBenefit,
      filterWithoutDiscount,
      sortIcon,
      sortBy,
      selectAllCheckbox,
      onNodeExpand,
      onToggleCheckbox,
      onShowDiscountInput,
      saveDiscountInput,
      onDiscountInputChange,
      haveConfirmationPopup,
      showEditPopup,
      onEditDiscountSave,
      deleteDiscount,
      saveDiscount,
      onRemoveDiscount
    }
  },
  components: {
    Input,
    InputElement,
    Loader,
    Checkbox,
    Dropdown,
    MessageBar,
    HierarchyRow,
    Popup,
    Button,
    PopupElement
  }
})
