
import { defineComponent, onMounted, computed, onUnmounted } from 'vue'
import Button from '@/components/UI/Button.vue'
import InputElement from '@/components/UI/Input.vue'
import { ref, reactive } from 'vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import { useStore } from '@/store'
import { leaseActionTypes } from '@/store/leaseCategories/actions'
import { VueCookieNext } from 'vue-cookie-next'
import { useRoute } from 'vue-router'
import ConvertToCommas from '@/filters/ConvertToCommas'
import CurrencyFormat from '@/filters/CurrencyFormat'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import Popup from '@/components/Popup.vue'
import { MutationTypes } from '@/store/leaseCategories/mutations'
import Loader from '@/components/UI/Loader.vue'
import MessageBar from '@/components/UI/MessageBar.vue'
import getCompany from '@/mixins/getCompany'
export default defineComponent({
  components: {
    Button,
    InputElement,
    Dropdown,
    Popup,
    Loader,
    MessageBar
  },
  name: 'Edit lease categorie',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n({ useScope: 'global' })
    const showPopup = ref(false)
    const fuelTypeExceptionsErrors = reactive({})
    const leaseCategoryByID = computed(() => store.state.leaseCategories.leaseCategoryByID)
    const loader = computed(() => store.state.leaseCategories.loader)
    const updateLeaseCategoryResponse = computed(() => store.state.leaseCategories.updateLeaseCategoryResponse)
    const roles = VueCookieNext.getCookie('roles')
    const { compId, companyDetailState } = getCompany()
    const leaseCategory = reactive({
      value: {
        leaseCategoryTitle: '',
        leaseAmount: '',
        leaseOverrun: '',
        leaseUndershoot: '',
        leasePersonalContribution: '',
        leasePrivateRate: '',
        leaseMaxC02: '',
        leaseSelectedOverrunItem: '%'
      }
    })
    const leaseBudgetPerFuelType = reactive({
      value: []
    })
    const overrunItems = reactive(['%', '€'])
    const selectedOverrunItem = ref('%')
    const onUpdateLeaseCategory = (value) => {
      leaseCategory.value['leaseCategoryTitle'] = value
    }
    const onUpdateLeaseAmount = (value) => {
      leaseCategory.value['leaseAmount'] = value
    }
    const onUpdateoverrun = (value) => {
      leaseCategory.value['leaseOverrun'] = value
    }
    const onUpdateundershoot = (value) => {
      leaseCategory.value['leaseUndershoot'] = value
    }
    const onUpdatePersonalContribution = (value) => {
      leaseCategory.value['leasePersonalContribution'] = value
    }
    const onUpdatePrivateRate = (value) => {
      leaseCategory.value['leasePrivateRate'] = value
    }
    const onUpdateMaxCo2 = (value) => {
      leaseCategory.value['leaseMaxC02'] = value
    }
    const selectOverrunItem = (value) => {
      leaseCategory.value['leaseSelectedOverrunItem'] = value
    }
    const getBreadCrumbTitle = () => {
      let title = t('leaseCategories.newleaseCategory')
      if (route.params.id) {
        title = leaseCategoryByID.value.title
      }
      return title
    }
    const convertToNumber = (selectedOption) => {
      return parseFloat(selectedOption.replace('€', '').replace(',', '.'))
    }
    const validateFloat = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /^\d+([\,]\d+)*([\.]\d+)?$/gm
      return regex.test(value)
    }
    const validateFuelTypeExceptions = () => {
      delete fuelTypeExceptionsErrors['petrolError']
      delete fuelTypeExceptionsErrors['dieselError']
      delete fuelTypeExceptionsErrors['electricError']
      delete fuelTypeExceptionsErrors['hydrogenError']
      delete fuelTypeExceptionsErrors['otherError']
      delete fuelTypeExceptionsErrors['petrolValidationError']
      delete fuelTypeExceptionsErrors['dieselValidationError']
      delete fuelTypeExceptionsErrors['electricValidationError']
      delete fuelTypeExceptionsErrors['hydrogenValidationError']
      delete fuelTypeExceptionsErrors['otherValidationError']
      if (leaseBudgetPerFuelType.value.length) {
        leaseBudgetPerFuelType.value.forEach((item) => {
          if (!item.amount) {
            fuelTypeExceptionsErrors[item.fuelType + 'Error'] = 'required'
          }
          if (item.amount && !validateFloat(item.amount)) {
            fuelTypeExceptionsErrors[item.fuelType + 'ValidationError'] = 'required'
          }
        })
      }
      if (Object.getOwnPropertyNames(fuelTypeExceptionsErrors).length === 0) {
        return true
      }
    }
    const validateBlock = () => {
      const errorString = []
      if (leaseCategory.value.leasePrivateRate) {
        if (!validateFloat(leaseCategory.value.leasePrivateRate))
          errorString.push('Max personal contribution percentage must be a number or left empty')
        if (convertToNumber(leaseCategory.value.leasePrivateRate) > 100)
          errorString.push('Max personal contribution percentage should contain number from 0 to 100')
      }
      if (leaseCategory.value.leaseUndershoot) {
        if (!validateFloat(leaseCategory.value.leaseUndershoot))
          errorString.push('Undershoot payout percentage must be a number or left empty')
        if (convertToNumber(leaseCategory.value.leaseUndershoot) > 100)
          errorString.push('Undershoot payout percentage should contain number from 0 to 100')
      }
      if (errorString.length > 0) {
        store.commit(MutationTypes.SET_UPDATELEASECATEGORYRESPONSE, errorString)
        return false
      } else return true
    }
    const publishLeaseCategory = () => {
      if (validateFuelTypeExceptions() && validateBlock()) {
        const leaseData = {
          title: leaseCategory.value.leaseCategoryTitle,
          amount: leaseBudgetPerFuelType.value.length ? '0' : convertToNumber(leaseCategory.value.leaseAmount),
          overrun: leaseCategory.value.leaseOverrun ? convertToNumber(leaseCategory.value.leaseOverrun) : null,
          undershootPayoutPercentage: leaseCategory.value.leaseUndershoot
            ? convertToNumber(leaseCategory.value.leaseUndershoot)
            : null,
          overrunType:
            leaseCategory.value.leaseSelectedOverrunItem == '€' ? '$' : leaseCategory.value.leaseSelectedOverrunItem,
          personalContribution: convertToNumber(leaseCategory.value.leasePersonalContribution),
          maxPersonalContributionPercentage: leaseCategory.value.leasePrivateRate
            ? convertToNumber(leaseCategory.value.leasePrivateRate)
            : null,
          co2Emission:
            leaseCategory.value.leaseMaxC02 != null ? convertToNumber(leaseCategory.value.leaseMaxC02) : null,
          multipleFuelTypesAmount: leaseBudgetPerFuelType.value.map((item) => {
            return { fuelType: item.fuelType, amount: convertToNumber(item.amount) }
          })
        }
        if (route.params.id) {
          store.dispatch(leaseActionTypes.UPDATE_LEASEBYID, {
            companyId: compId,
            leaseCategoryId: leaseCategoryByID.value.id,
            data: leaseData
          })
        } else {
          store.dispatch(leaseActionTypes.ADD_LEASECATEGORY, {
            companyId: compId,
            data: leaseData
          })
        }
      }
    }
    const deleteLeaseCategory = () => {
      if (route.params.id) {
        showPopup.value = true
      } else {
        router.push({ name: 'LeaseCategoryOverview' })
      }
    }
    const discardLeaseCategory = () => {
      router.push({ name: 'LeaseCategoryOverview' })
    }
    const closePopup = () => {
      showPopup.value = false
    }
    const onProceed = () => {
      store.dispatch(leaseActionTypes.DELETE_LEASEBYID, {
        companyId: compId,
        leaseCategoryId: leaseCategoryByID.value.id
      })
      showPopup.value = false
    }
    const initialize = async () => {
      await store.dispatch(leaseActionTypes.GET_LEASEBYID, {
        companyId: compId,
        leaseCategoryId: route.params.id
      })
    }
    const addLeaseBudgetPerFuelType = () => {
      leaseBudgetPerFuelType.value.push(
        { fuelType: 'petrol', amount: '' },
        { fuelType: 'diesel', amount: '' },
        { fuelType: 'electric', amount: '' },
        { fuelType: 'hydrogen', amount: '' },
        { fuelType: 'other', amount: '' }
      )
    }
    const onLeaseBudgetPerFuelTypeInput = (value, key) => {
      const selectedFuelTypeIndex = leaseBudgetPerFuelType.value.findIndex((item) => item['fuelType'] == key)
      leaseBudgetPerFuelType.value[selectedFuelTypeIndex]['amount'] = value
    }
    const deleteLeaseBudget = () => {
      leaseBudgetPerFuelType.value = []
    }
    onMounted(async () => {
      if (route.params.id) {
        await initialize()
        leaseCategory.value['leaseCategoryTitle'] = leaseCategoryByID.value.title
        leaseCategory.value['leaseAmount'] = leaseCategoryByID.value.amount.toString()
        leaseCategory.value['leaseOverrun'] =
          leaseCategoryByID.value.overrun == null ? null : leaseCategoryByID.value.overrun.toString()
        leaseCategory.value['leaseUndershoot'] =
          leaseCategoryByID.value.undershootPayoutPercentage == null
            ? null
            : leaseCategoryByID.value.undershootPayoutPercentage.toString()
        leaseCategory.value['leasePersonalContribution'] = leaseCategoryByID.value.personalContribution.toString()
        leaseCategory.value['leasePrivateRate'] =
          leaseCategoryByID.value.maxPersonalContributionPercentage == null
            ? null
            : leaseCategoryByID.value.maxPersonalContributionPercentage.toString()
        leaseCategory.value['leaseMaxC02'] =
          leaseCategoryByID.value.co2Emission !== null
            ? ConvertToCommas(leaseCategoryByID.value.co2Emission.toString())
            : null
        if (leaseCategoryByID.value.multipleFuelTypesAmount == null) {
          leaseBudgetPerFuelType.value = []
        } else {
          leaseBudgetPerFuelType.value = leaseCategoryByID.value.multipleFuelTypesAmount.map((item) => {
            return { fuelType: item.fuelType, amount: item.amount.toString() }
          })
        }
        if (leaseCategoryByID.value.overrunType == '$') {
          leaseCategory.value['leaseSelectedOverrunItem'] = '€'
        } else {
          leaseCategory.value['leaseSelectedOverrunItem'] = leaseCategoryByID.value.overrunType
        }
      }
    })
    onUnmounted(() => {
      store.commit(MutationTypes.SET_UPDATELEASECATEGORYRESPONSE, [])
    })
    return {
      onUpdateLeaseCategory,
      onUpdateoverrun,
      onUpdateundershoot,
      onUpdateLeaseAmount,
      onUpdatePersonalContribution,
      onUpdatePrivateRate,
      onUpdateMaxCo2,
      publishLeaseCategory,
      overrunItems,
      selectOverrunItem,
      selectedOverrunItem,
      leaseCategory,
      ConvertToCommas,
      CurrencyFormat,
      leaseCategoryByID,
      getBreadCrumbTitle,
      showPopup,
      deleteLeaseCategory,
      closePopup,
      onProceed,
      updateLeaseCategoryResponse,
      loader,
      discardLeaseCategory,
      route,
      addLeaseBudgetPerFuelType,
      leaseBudgetPerFuelType,
      deleteLeaseBudget,
      onLeaseBudgetPerFuelTypeInput,
      fuelTypeExceptionsErrors,
      companyDetailState,
      roles
    }
  }
})
